import CLO from "../assets/clo.svg";
import CLOSET from "../assets/closet.svg";
import MD from "../assets/md.svg";
import { Brand } from "../context/AppProvider";
import { useAppContext } from "../hooks/useAppContext";

const ChatProfile = () => {
  const { brand, getTranslation } = useAppContext();

  let src = "";
  let alt = "";
  if (brand === Brand.MD) {
    src = MD;
    alt = "MD";
  } else if (brand === Brand.CLOSET) {
    src = CLOSET;
    alt = "CLOSET";
  } else {
    src = CLO;
    alt = "CLO";
  }

  return (
    <div className="flex w-full items-center justify-start gap-[6px]">
      <img className="h-[30px] w-[30px]" src={src} alt={alt} />
      <span className="text-sm font-bold leading-snug text-zinc-800 md:text-base lg:text-lg">
        {brand === Brand.MD
          ? "Marvelous Designer"
          : brand === "CLOSET"
            ? "CLO-SET"
            : "CLO"}{" "}
        Chatbot
      </span>
    </div>
  );
};

export default ChatProfile;
