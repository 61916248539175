import React from "react";

const Info = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C13.95 0 18 4.05 18 9C18 13.95 13.95 18 9 18C4.05 18 0 13.95 0 9C0 4.05 4.05 0 9 0ZM8.35148 7.61538C8.02434 7.61538 7.67848 7.671 7.31244 7.78213C7.06842 7.85602 6.81701 7.94 6.55789 8.03398L6.16341 8.18244L6 8.82041L6.12694 8.77719L6.42762 8.68578C6.59312 8.63896 6.75549 8.61476 6.91347 8.61476C7.23602 8.61476 7.45328 8.66737 7.56713 8.771C7.68099 8.87493 7.73818 9.05978 7.73818 9.32405C7.73818 9.47019 7.72009 9.63253 7.68256 9.80898C7.65788 9.92735 7.62912 10.0502 7.59658 10.1777L7.54497 10.3724L6.93386 12.4418C6.87949 12.6593 6.83976 12.8538 6.81478 13.0266C6.79 13.1991 6.77808 13.3685 6.77808 13.5331C6.77808 13.9584 6.94233 14.309 7.27073 14.5857C7.59913 14.8613 8.05957 15 8.65155 15C9.03703 15 9.37536 14.9518 9.66654 14.8549L9.9906 14.7436L10.837 14.433L11 13.7956L10.9271 13.825L10.7235 13.8902C10.6832 13.9018 10.6397 13.9138 10.5931 13.9261C10.406 13.9754 10.2377 14.0005 10.0898 14.0005C9.77465 14.0005 9.55279 13.9511 9.42398 13.8518C9.29611 13.7525 9.23234 13.5656 9.23234 13.2919C9.23234 13.2269 9.23926 13.1427 9.2535 13.04L9.29183 12.8103C9.31782 12.6683 9.34643 12.5369 9.37738 12.416L9.42555 12.2425L10.034 10.1815C10.0936 9.99233 10.1345 9.78438 10.1565 9.55742L10.1843 9.22335L10.1894 9.08259C10.1894 8.64797 10.0302 8.29527 9.7116 8.0232C9.39303 7.75132 8.93938 7.61538 8.35148 7.61538ZM9.0048 3C8.59163 3 8.23796 3.13568 7.9429 3.40714C7.64785 3.67902 7.5 4.00607 7.5 4.38765C7.5 4.76997 7.64785 5.09639 7.9429 5.36497C8.2385 5.63441 8.59153 5.76923 9.0048 5.76923C9.41677 5.76923 9.76894 5.63441 10.0612 5.36497C10.3542 5.09649 10.5 4.76997 10.5 4.38765C10.5 4.00618 10.3544 3.67902 10.0612 3.40714C9.76894 3.136 9.41666 3 9.0048 3Z"
      />
    </svg>
  );
};

export default Info;
