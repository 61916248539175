import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useAppContext } from "../hooks/useAppContext";
import { getEncoding, encodingForModel } from "js-tiktoken";
import SendArrow from "./SVGIcons/SendArrow";
import { AnswerLoading } from "./AnswerLoading";
import SettingButton from "./SettingButton";

interface Props {
  question: string;
  setQuestion: React.Dispatch<React.SetStateAction<string>>;
  tokens: number;
  setTokens: React.Dispatch<React.SetStateAction<number>>;
  settingPanelOpen: boolean;
  setSettingPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onSend: (question: string) => void;
  firstQuestion?: boolean;
  disabled: boolean;
  placeholder?: string;
}

export const InputQuestion = ({
  question,
  setQuestion,
  tokens,
  setTokens,
  settingPanelOpen,
  setSettingPanelOpen,
  onSend,
  firstQuestion,
  disabled,
  placeholder,
}: Props) => {
  const MAXTOKENS = 250;
  const { getTranslation } = useAppContext();
  const [inputError, setInputError] = useState("");
  const textbox = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (!textbox.current) return;

    textbox.current.style.height = "inherit";
    textbox.current.style.height = `${textbox.current.scrollHeight}px`;
  };

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      setInputError("Please ask a question.");
      return;
    }

    if (tokens > MAXTOKENS) {
      setInputError(
        `Number of tokens exceeds ${MAXTOKENS}. Please reduce the length of your question.`,
      );
      return;
    }

    onSend(question);
    setInputError("");
    setTokens(0);
    setQuestion("");
  };

  const onKeyDown = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendQuestion();
    }
  };

  const onQuestionChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const question = e.currentTarget.value;
    setQuestion(question);
    adjustHeight();
  };

  useEffect(() => {
    let debouncer = setTimeout(() => {
      const gpt3Enc = getEncoding("gpt2");
      const encoded = gpt3Enc.encode(question);

      if (inputError && encoded.length <= MAXTOKENS) {
        setInputError("");
      }

      setTokens(encoded.length);
    }, 500);
    return () => {
      clearTimeout(debouncer);
    };
  }, [question]);

  useLayoutEffect(adjustHeight, [question]);

  return (
    <div className="relative w-full md:w-[1200px]">
      {firstQuestion ? (
        <div className="flex w-full flex-col items-center gap-2">
          <span className="ml-4 h-[20px] text-sm font-bold text-red-500">
            {inputError}
          </span>
          <div className="flex h-[50px] w-full grow items-center justify-start rounded-2xl bg-indigo-400 px-3.5 py-1.5">
            <div className="grow">
              <AnswerLoading className="bg-white" />
            </div>
            <div className="flex flex-col justify-end">
              <button
                className={`cursor-pointer rounded-[14px] p-2 group-focus:bg-primary`}
                aria-label={placeholder}
                onClick={sendQuestion}
              >
                <SendArrow className="h-[18px] w-[18px] fill-[#A5B8FF] group-hover:fill-primary group-focus:bg-primary group-focus:fill-white" />
              </button>
            </div>
          </div>
          <span className={`ml-4 h-[20px] self-start text-sm font-bold`}></span>
        </div>
      ) : (
        <div className="flex w-full flex-col items-center gap-2">
          <span className="ml-4 h-[20px] self-start text-sm font-bold text-red-500">
            {inputError}
          </span>
          <div className="group flex max-h-[120px] w-full items-center gap-4 rounded-[18px] px-4 py-2 shadow ring-2 ring-indigo-300 hover:ring-[3px] focus:bg-[#7891FF] lg:w-[1200px]">
            <textarea
              className="max-h-[100px] w-full resize-none break-words bg-transparent pr-1 text-indigo-500 focus:outline-none group-focus:bg-[#7891FF] group-focus:text-white group-focus:placeholder:text-white [&::-webkit-scrollbar-thumb]:bg-indigo-500 [&::-webkit-scrollbar-thumb]:[border-radius:10px] [&::-webkit-scrollbar]:[width:6px]"
              placeholder={placeholder}
              value={question}
              onChange={onQuestionChange}
              onKeyDown={onKeyDown}
              aria-label="Question"
              rows={1}
              ref={textbox}
            />
            <div className="flex flex-col justify-end">
              <button
                className={`cursor-pointer rounded-[14px] p-2 group-focus:bg-primary`}
                aria-label={placeholder}
                onClick={sendQuestion}
              >
                <SendArrow className="h-[18px] w-[18px] fill-[#A5B8FF] group-hover:fill-primary group-focus:bg-primary group-focus:fill-white" />
              </button>
            </div>
          </div>
          <span
            className={`ml-4 h-[20px] self-start text-sm font-bold ${tokens > MAXTOKENS ? "text-red-500" : "text-gray-700"}`}
          >
            {tokens}/{MAXTOKENS}
          </span>
        </div>
      )}
      {import.meta.env.VITE_ENV === "DEV" && (
        <div className="absolute -top-[15px] right-0">
          <SettingButton
            onClick={() => setSettingPanelOpen(!settingPanelOpen)}
          />
        </div>
      )}
    </div>
  );
};
