import React from "react";

const SendArrow = ({ className }: { className?: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.89066 9.92845C2.41699 9.92845 1.99922 10.2361 1.86225 10.6858L0.0476593 16.6436C-0.202903 17.4663 0.579752 18.2252 1.39641 17.938C7.13923 15.9186 12.554 13.1906 17.5382 9.85769C18.1539 9.44592 18.1539 8.55388 17.5382 8.14211C12.5539 4.80922 7.13913 2.08137 1.39627 0.0619465C0.579614 -0.225222 -0.203029 0.53372 0.0475315 1.35638L1.86225 7.31457C1.99922 7.76429 2.41699 8.07197 2.89066 8.07197L10.603 8.07197C11.1105 8.07197 11.5219 8.48756 11.5219 9.00021C11.5219 9.51287 11.1105 9.92846 10.603 9.92846L2.89066 9.92845Z"
      />
    </svg>
  );
};

export default SendArrow;
