import axios from "axios";

export const chatAxios = axios.create();

chatAxios.interceptors.request.use(async req => {
    return req;
});

// Response interceptor for API calls
chatAxios.interceptors.response.use(response => {
    return response;
});
