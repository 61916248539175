import CLO from "../assets/clo.svg";
import CLOSET from "../assets/closet.svg";
import MD from "../assets/md.svg";
import { Brand } from "../context/AppProvider";
import { useAppContext } from "../hooks/useAppContext";

type ModalBrandProps = {
  className?: string;
  menuDropDown: boolean;
  setMenuDropDown: React.Dispatch<React.SetStateAction<boolean>>;
};

const ModalBrand = ({
  className,
  menuDropDown,
  setMenuDropDown,
}: ModalBrandProps) => {
  const { brand, setBrand, getTranslation } = useAppContext();

  return (
    <ul
      className={`${className} z-10 flex flex-col items-start justify-center gap-1 rounded-2xl bg-white p-3.5 shadow`}
    >
      <li
        className={`group flex h-24 w-full cursor-pointer items-center justify-start gap-4 rounded-xl px-3.5 py-2.5 hover:bg-slate-100 sm:rounded-[14px] sm:px-4 sm:py-3 ${brand === Brand.CLO3D ? "bg-slate-100" : ""}`}
        onClick={() => {
          setBrand(Brand.CLO3D);
          setMenuDropDown(false);
        }}
      >
        <img className="h-[50px] w-[50px]" src={CLO} alt="CLO" />
        <div className="flex h-full flex-col justify-center">
          <h6 className="text-xs font-bold leading-[17px] text-zinc-800 group-hover:text-indigo-500 sm:text-base md:leading-snug">
            {Brand.CLO3D}
          </h6>
          <p className="text-[10px] font-normal leading-[14px] text-zinc-800 group-hover:text-indigo-500 sm:text-xs sm:leading-[17px]">
            {getTranslation("CLO Description")}
          </p>
        </div>
      </li>
      <li
        className={`group flex h-24 w-full cursor-pointer items-center justify-start gap-4 rounded-[14px] px-4 py-3 hover:bg-slate-100 ${brand === Brand.CLOSET ? "bg-slate-100" : ""}`}
        onClick={() => {
          setBrand(Brand.CLOSET);
          setMenuDropDown(false);
        }}
      >
        <img className="h-[50px] w-[50px]" src={CLOSET} alt="CLOSET" />
        <div className="flex h-full flex-col justify-center">
          <h6 className="text-xs font-bold leading-[17px] text-zinc-800 group-hover:text-indigo-500 sm:text-base md:leading-snug">
            CLO-SET
          </h6>
          <p className="text-[10px] font-normal leading-[14px] text-zinc-800 group-hover:text-indigo-500 sm:text-xs sm:leading-[17px]">
            {getTranslation("CLOSET Description")}
          </p>
        </div>
      </li>
      {/* <li className="group flex h-24 w-full cursor-pointer items-center justify-start gap-4 rounded-[14px] px-4 py-3 hover:bg-slate-100">
        <img className="h-[50px] w-[50px]" src={CONNECT} alt="CONNECT" />
        <div className="flex flex-col justify-center h-full">
          <h6 className="text-xs font-bold leading-[17px] text-zinc-800 group-hover:text-indigo-500 sm:text-base md:leading-snug">
            Connect
          </h6>
          <p className="text-[10px] font-normal leading-[14px] text-zinc-800 group-hover:text-indigo-500 sm:text-xs sm:leading-[17px]">
            {getTranslation("CONNECT Description")}
          </p>
        </div>
      </li>*/}
      {import.meta.env.VITE_ENV === "DEV" && (
        <li
          className="group flex h-24 w-full cursor-pointer items-center justify-start gap-4 rounded-[14px] px-4 py-3 hover:bg-slate-100"
          onClick={() => {
            setBrand(Brand.MD);
            setMenuDropDown(false);
          }}
        >
          <img className="h-[50px] w-[50px]" src={MD} alt="MD" />
          <div className="flex h-full flex-col justify-center">
            <h6 className="text-xs font-bold leading-[17px] text-zinc-800 group-hover:text-indigo-500 sm:text-base md:leading-snug">
              Marvelous Designer
            </h6>
            <p className="text-[10px] font-normal leading-[14px] text-zinc-800 group-hover:text-indigo-500 sm:text-xs sm:leading-[17px]">
              {getTranslation("Marvelous Design Description")}
            </p>
          </div>
        </li>
      )}
    </ul>
  );
};

export default ModalBrand;
