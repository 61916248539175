type EmbedYoutubeVideoProps = {
  url: string;
};
const EmbedYoutubeVideo = ({ url }: EmbedYoutubeVideoProps) => {
  const embedId = url.split("watch?v=")[1];

  return (
    <iframe
      className="rounded-lg"
      key={embedId}
      width="125"
      height="70"
      src={`https://www.youtube.com/embed/${embedId}`}
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
    />
  );
};

export default EmbedYoutubeVideo;
