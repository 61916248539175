import { Tooltip } from "react-tooltip";
import { useAppContext } from "../../hooks/useAppContext";

type ThumbsUpProps = {
  className?: string;
  onClick?: () => void;
};

const ThumbsUp = ({ className, onClick }: ThumbsUpProps) => {
  const { getTranslation } = useAppContext();

  return (
    <>
      <svg
        className={className}
        onClick={onClick}
        data-tooltip-id="thumbs-up-tooltip"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g clipPath="url(#clip0_94_4064)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.039 1.16227C12.1195 1.38759 12.1306 1.63138 12.0707 1.86287L11.1274 5.51611H16.3637C16.7977 5.51611 17.2139 5.68604 17.5208 5.98852C17.8277 6.29099 18.0001 6.70124 18.0001 7.12901V8.82579C18.0003 9.03657 17.9586 9.24534 17.8774 9.44031L15.3451 15.5008C15.2833 15.6486 15.1784 15.7749 15.0437 15.8638C14.9089 15.9527 14.7504 16.0001 14.5883 16H5.72736C5.51037 16 5.30226 15.915 5.14882 15.7638C4.99538 15.6126 4.90918 15.4074 4.90918 15.1935V5.84998C4.90923 5.63611 4.99546 5.43102 5.14891 5.27981L10.3853 0.118513C10.4549 0.0497172 10.5473 0.00800169 10.6456 0.00103779C10.7439 -0.00592612 10.8415 0.0223298 10.9204 0.0806092L11.6183 0.596739C11.8121 0.740163 11.9585 0.936944 12.039 1.16227ZM16.3001 7.21611V8.80571L14.0044 14.3H6.60918V6.22747L10.0684 2.81788L8.93261 7.21611H16.3001Z"
          />
          <path d="M3.27273 6.32257H0.818182C0.601187 6.32257 0.393079 6.40754 0.23964 6.55878C0.086201 6.71001 0 6.91514 0 7.12902V15.1936C0 15.4074 0.086201 15.6126 0.23964 15.7638C0.393079 15.915 0.601187 16 0.818182 16H3.27273V6.32257Z" />
        </g>
        <defs>
          <clipPath id="clip0_94_4064">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Tooltip id="thumbs-up-tooltip" className="z-10">
        {getTranslation("Thumbs Up Tooltip")}
      </Tooltip>
    </>
  );
};

export default ThumbsUp;
