import React from "react";
import Close from "./SVGIcons/Close";
import ModalBackground from "./ModalBackground";
import { Transition } from "@headlessui/react";

const ModalOverlay = ({
  children,
  open,
  setOpen,
}: {
  children: React.ReactNode;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <>
      <Transition
        className="fixed -bottom-[380px] left-[calc(50vw-150px)] z-10 w-[300px]"
        show={open}
        as="div"
        enter="transition-translate duration-[600ms]"
        enterTo="-translate-y-[calc(50vh+190px)]"
        leave="transition-translate duration-[600ms]"
        leaveFrom="-translate-y-[calc(50vh+190px)]"
        leaveTo="translate-y-[calc(50vh+190px)]"
        appear={true}
      >
        <Close
          className="width-[30px] height-[30px] absolute right-3 top-3 cursor-pointer hover:fill-primary"
          onClick={() => setOpen(false)}
        />
        <div className="flex flex-col items-center justify-center gap-5 rounded-2xl bg-white px-8 py-10 shadow">
          {children}
        </div>
      </Transition>
      {open && <ModalBackground setVisible={setOpen} />}
    </>
  );
};

export default ModalOverlay;
