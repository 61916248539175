import React, { useState } from "react";
import { Brand } from "../context/AppProvider";
import { useAppContext } from "../hooks/useAppContext";
import DropDownArrow from "./SVGIcons/DropDownArrow";

export type BrandProps = {
  setMenuDropDown: React.Dispatch<React.SetStateAction<boolean>>;
};

const BrandButtonNav = ({ setMenuDropDown }: BrandProps) => {
  const { brand, getTranslation } = useAppContext();

  return (
    <button
      className="group"
      onClick={() => setMenuDropDown((menuDropDown) => !menuDropDown)}
    >
      <p className="text-left text-[11px] font-normal leading-[15px] text-zinc-800">
        {getTranslation("Interested in other products?")}
      </p>
      <div className="flex h-[34px] items-center gap-3">
        <span className="text-xl font-bold leading-7 text-zinc-800 group-hover:text-primary">
          {brand === Brand.MD
            ? "Marvelous Design"
            : brand === Brand.CLOSET
              ? "CLO-SET"
              : "CLO"}
        </span>
        <DropDownArrow className={"fill-indigo-300 group-hover:fill-primary"} />
      </div>
    </button>
  );
};

export default BrandButtonNav;
