import Privacy from "../assets/privacy.svg";
import { useAppContext } from "../hooks/useAppContext";

const ModalPrivacy = () => {
  const { getTranslation } = useAppContext();
  return (
    <>
      <img className="h-[70px] w-[70px]" src={Privacy} alt="" />
      <h1 className="text-center text-sm font-bold leading-tight text-zinc-800 sm:text-base">
        {getTranslation("Privacy Notice")}
      </h1>
      <ul className="flex flex-col gap-2">
        <li
          className={`list-disc text-[10px] font-normal leading-[14px] text-zinc-800 sm:text-[14px]`}
        >
          {getTranslation("Privacy Policy #1")}
        </li>
        <li
          className={`list-disc text-[10px] font-normal leading-[14px] text-zinc-800 sm:text-[14px]`}
        >
          {getTranslation("Privacy Policy #2")}
        </li>
      </ul>
    </>
  );
};

export default ModalPrivacy;
