import React from "react";
import PrivacyIcon from "../../assets/privacy.svg";
import { useAppContext } from "../../hooks/useAppContext";

type PrivacyProps = {
  className?: string;
  onMouseEnter?: () => void;
};

const Privacy = ({ className, onMouseEnter }: PrivacyProps) => {
  const { getTranslation } = useAppContext();

  return (
    <div
      className={`${className} flex min-w-[90px] flex-col items-center justify-center gap-2.5`}
      onMouseEnter={onMouseEnter}
    >
      <img
        className="h-[50px] w-[50px] cursor-pointer md:h-[80px] md:w-[80px]"
        src={PrivacyIcon}
      />
      <span className="text-center text-xs font-bold leading-[17px] text-zinc-800">
        {getTranslation("Privacy Notice")}
      </span>
    </div>
  );
};

export default Privacy;
