import { useEffect, useMemo, useState } from "react";
import DOMPurify from "dompurify";
import { parseAnswerToHtml } from "./AnswerParser";
import EmbedYoutubeVideo from "./EmbedYoutubeVideo";
import { useAppContext } from "../hooks/useAppContext";
import { AskResponse } from "../api/models";
import { thumbsApi } from "../api/chatAPI";
import Glitter from "../assets/glitter.svg";
import ThumbsUp from "./SVGIcons/ThumbsUp";
import ThumbsDown from "./SVGIcons/ThumbsDown";
import Book from "../assets/book.svg";
import Video from "../assets/video.svg";
import ModalFeedback from "./ModalFeedback";
import ModalBackground from "./ModalBackground";
import ChatProfile from "./ChatProfile";

interface Props {
  answers: AskResponse;
  isSelected?: boolean;
  onCitationClicked?: (filePath: string) => void;
  onThoughtProcessClicked?: () => void;
  onSupportingContentClicked?: () => void;
  onFollowupQuestionClicked: (question: string) => void;
  showFollowupQuestions?: boolean;
  RedoButton?: JSX.Element;
}

export enum Thumb {
  Up = "UP",
  Down = "DOWN",
}

export const Answer = ({
  answers,
  isSelected,
  onCitationClicked,
  onThoughtProcessClicked,
  onSupportingContentClicked,
  onFollowupQuestionClicked,
  showFollowupQuestions,
  RedoButton,
}: Props) => {
  const { brand, getTranslation, locale } = useAppContext();
  const [feedbackOpen, setFeedbackOpen] = useState<boolean>(false);
  const [thumbUp, setThumbUp] = useState<boolean>(false);
  const [thumbDown, setThumbDown] = useState<boolean>(false);
  const parsedAnswer = useMemo(
    () =>
      parseAnswerToHtml(answers.answer, answers.follow_up_questions, locale),
    [answers],
  );

  const onThumbUpClicked = async (_id: string) => {
    try {
      if (thumbUp) {
        await thumbsApi(brand, _id, 0, 0, [], "");
      } else {
        await thumbsApi(brand, _id, 1, 0, [], "");
      }
      setThumbUp(!thumbUp);
      setThumbDown(false);
    } catch (e) {
      console.log(e);
    }
  };

  const onThumbDownClicked = async (
    _id: string,
    feedbackChoices: string[],
    feedbackDetails: string,
  ) => {
    try {
      if (thumbDown) {
        await thumbsApi(brand, _id, 0, 0, [], "");
      } else {
        await thumbsApi(brand, _id, 0, 1, feedbackChoices, feedbackDetails);
      }
      setThumbUp(false);
      setThumbDown(!thumbDown);
    } catch (e) {
      console.log(e);
    }
  };

  // useEffect(() => {
  // console.log("Parsed Answer: \n", parsedAnswer.answerHtml, "\nCitations:", parsedAnswer.citations);
  // console.log(answer);
  // }, [answer]);

  return (
    <>
      <div className="flex flex-col items-start justify-center gap-2.5 py-2.5 md:w-[1000px]">
        <ChatProfile />
        <div className="flex flex-col items-start justify-start w-full gap-4 p-4 bg-white bg-opacity-50 border border-white shadow rounded-br-3xl rounded-tl-3xl rounded-tr-3xl">
          <div
            className="whitespace-pre-line text-sm font-normal leading-snug text-zinc-800 sm:text-[15px] [&>a]:text-primary [&>a]:hover:underline"
            dangerouslySetInnerHTML={{
              __html: parsedAnswer.answerHtml,
            }}
          />
          <div className="flex w-full flex-col items-start justify-start gap-3.5">
            {!!parsedAnswer.citations.length && (
              <div className="flex w-full flex-col gap-1.5">
                <div className="w-full h-px bg-gray-200" />
                <div className="flex h-6 items-center justify-start gap-2.5 py-1.5">
                  <span className="text-xs font-bold leading-[14px] text-neutral-500 sm:text-sm">
                    {getTranslation("Citations")}
                  </span>
                  <img className="w-3 h-3" src={Book} alt="" />
                </div>
                <ul className="flex flex-wrap justify-start gap-0.5">
                  {parsedAnswer.citations.map((x, i) => {
                    let citation = answers?.citation_articles?.find(
                      (citation) => {
                        return citation.url.includes(x);
                      },
                    );
                    return (
                      <li className="rounded-[10px] bg-slate-100" key={i}>
                        <a
                          className="block cursor-pointer rounded-[10px] p-2 text-xs font-normal leading-[17px] text-indigo-500 hover:bg-primary-hover hover:text-white sm:text-sm"
                          href={x}
                          target="_blank"
                        >
                          {`${++i}. ${citation?.title ? citation.title : x}`}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {!!answers.suggested_articles?.length && (
              <div className="flex w-full flex-col gap-1.5">
                <div className="w-full h-px bg-gray-200" />
                <div className="flex h-6 items-center justify-start gap-2.5 py-1.5">
                  <span className="text-xs font-bold leading-[14px] text-neutral-500 sm:text-sm">
                    {getTranslation("Suggested Articles")}
                  </span>
                  <img className="w-3 h-3" src={Glitter} alt="" />
                </div>
                <ul className="flex flex-wrap justify-start gap-0.5">
                  {answers.suggested_articles.map((x, i) => {
                    return (
                      <li className="rounded-[10px] bg-slate-100" key={i}>
                        <a
                          className="block cursor-pointer rounded-[10px] p-2 text-xs font-normal leading-[17px] text-indigo-500 hover:bg-primary-hover hover:text-white sm:text-sm"
                          href={x.url}
                          target="_blank"
                        >
                          {`${x.title}`}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {!!answers.youtube_links?.length && (
              <div className="flex w-full flex-col gap-1.5">
                <div className="w-full h-px bg-gray-200" />
                <div className="flex h-6 items-center justify-start gap-2.5 py-1.5">
                  <span className="text-xs font-bold leading-[14px] text-neutral-500 sm:text-sm">
                    {getTranslation("Youtube")}
                  </span>
                  <img className="w-3 h-3" src={Video} alt="" />
                </div>
                <ul className="flex flex-wrap justify-start gap-0.5">
                  {answers.youtube_links.slice(0, 3).map((x, i) => {
                    return (
                      <li key={i}>
                        <EmbedYoutubeVideo key={i} url={x} />
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            {!!parsedAnswer?.followupQuestions?.length && (
              <div className="flex w-full flex-col gap-1.5">
                <div className="w-full h-px bg-gray-200" />
                <div className="flex h-6 items-center justify-start gap-2.5 py-1.5">
                  <span className="text-xs font-bold leading-[14px] text-neutral-500 sm:text-sm">
                    {getTranslation("Follow-up Questions")}
                  </span>
                  <img className="w-3 h-3" src={Glitter} alt="" />
                </div>
                <ul className="flex flex-wrap justify-start gap-0.5">
                  {parsedAnswer.followupQuestions.map((x, i) => {
                    return (
                      <li key={i} className="rounded-[10px] bg-slate-100">
                        <a
                          className="block cursor-pointer rounded-[10px] p-2 text-xs font-normal leading-[17px] text-indigo-500 hover:bg-primary-hover hover:text-white sm:text-sm"
                          title={x}
                          onClick={() => onFollowupQuestionClicked(x)}
                          target="_blank"
                        >
                          {`${x}`}
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            )}
            <ul className="flex items-start justify-start gap-0.5 rounded-2xl border border-gray-50 bg-white px-2.5 py-0.5">
              <li className="flex h-[30px] w-[30px] items-center justify-center rounded-xl p-1.5">
                <ThumbsUp
                  className={`h-[18px] w-[18px] cursor-pointer hover:fill-primary ${thumbUp ? "fill-primary" : ""}`}
                  onClick={() => {
                    onThumbUpClicked(answers._id);
                  }}
                />
              </li>
              <li className="flex h-[30px] w-[30px] items-center justify-center rounded-xl p-1.5">
                <ThumbsDown
                  className={`h-[18px] w-[18px] cursor-pointer hover:fill-primary ${thumbDown ? "fill-primary" : ""}`}
                  onClick={() => {
                    if (!thumbDown) {
                      setFeedbackOpen(true);
                    } else {
                      onThumbDownClicked(answers._id, [], "");
                    }
                  }}
                />
              </li>
              <li className="flex h-[30px] w-[30px] items-center justify-center rounded-xl p-1.5">
                {RedoButton}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <ModalFeedback
        _id={answers._id}
        feedbackOpen={feedbackOpen}
        setFeedbackOpen={setFeedbackOpen}
        onThumbDownClicked={onThumbDownClicked}
      />
      {feedbackOpen && <ModalBackground setVisible={setFeedbackOpen} />}
    </>
  );
};
