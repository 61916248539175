import { Transition } from "@headlessui/react";
import { ReactNode, useEffect, useState } from "react";

type ToastProps = {
  showToast: boolean;
  setShowToast: React.Dispatch<React.SetStateAction<boolean>>;
  iconElement: ReactNode;
  message: string | undefined;
};

const Toast = ({
  showToast,
  setShowToast,
  iconElement,
  message,
}: ToastProps) => {
  useEffect(() => {
    if (showToast) {
      setTimeout(() => {
        setShowToast(false);
      }, 10000);
    }
  }, [showToast]);

  return (
    <Transition
      className="absolute -left-[316px] top-[85px] w-[calc(100vw-30px)] sm:w-[300px]"
      show={showToast}
      as="div"
      enter="transition-translate duration-[500ms] delay-[1000ms]"
      enterTo="translate-x-[332px]"
      leave="transition-translate duration-[500ms]"
      leaveFrom="translate-x-[332px]"
      leaveTo="-translate-x-[316px]"
      appear={true}
    >
      <div className="flex items-center gap-4 rounded-lg bg-primary p-4 text-white shadow">
        {iconElement}
        <div className="text-sm">{message}</div>
      </div>
    </Transition>
  );
};

export default Toast;
