import ReactDOM from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import Layout from "./pages/layout/Layout";
import Chat from "./pages/chat/Chat";
import { Error } from "./pages/error/Error";
import { LoadingSpinner } from "./components/LoadingSpinner";
import AppProvider from "./context/AppProvider";
import { CookiesProvider } from "react-cookie";

const router = createBrowserRouter([
  {
    element: <Layout />,
    errorElement: <Error />,
    children: [
      {
        path: "/",
        element: <Chat />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <CookiesProvider defaultSetOptions={{ path: "/" }}>
    <AppProvider>
      <RouterProvider router={router} fallbackElement={<LoadingSpinner />} />
    </AppProvider>
  </CookiesProvider>,
);
