interface Props {
  message: string;
}

export const UserChatMessage = ({ message }: Props) => {
  return (
    <div className="flex justify-end">
      <div className="flex flex-col items-center justify-start gap-4 rounded-bl-[20px] rounded-tl-[20px] rounded-tr-[20px] border border-indigo-500 bg-indigo-400 p-4 text-sm font-normal leading-[17px] text-white shadow sm:text-[15px]">
        {message}
      </div>
    </div>
  );
};
