import axios, { AxiosError } from "axios";
import { NavigateFunction } from "react-router-dom";
import { chatAxios } from "../axios/chatAxios";
import { Brand } from "../context/AppProvider";
import { AskResponse, ChatRequest, ThumbsResponse } from "./models";

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
  const config = {
    data: {
      history: options.history,
      approach: options.approach,
      overrides: {
        semantic_ranker: options.overrides?.semanticRanker,
        semantic_captions: options.overrides?.semanticCaptions,
        top: options.overrides?.top,
        temperature: options.overrides?.temperature,
        chat_record: options.overrides?.chatRecord,
        dummy_answer: options.overrides?.dummyAnswer,
        brand: options.overrides?.brand,
      },
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      locale: options.locale,
    },
    withCredentials: true,
  };

  try {
    const response = await chatAxios.post("/api/v1/chat", config);
    return response.data as AskResponse;
  } catch (error: any | AxiosError) {
    // if (error.response.status === 401) {
    //     navigate("/signin");
    // }
    throw error;
  }
}

/**
 * Increments or decrements the count of thumbs up or thumbs down in MongoDB
 * @param _id unique id of the article stored in MongoDB
 * @param thumbup increase count of thumbs up by given input
 * @param thumbdown increase count of thumbs down by given input
 * @returns Promise<ThumbsResponse[]>
 */
export async function thumbsApi(
  brand: Brand,
  _id: string,
  thumbup: number,
  thumbdown: number,
  feedbackChoices: string[],
  feedbackDetails: string,
): Promise<ThumbsResponse[]> {
  const config = {
    data: {
      brand: brand,
      _id: _id,
      thumbup: thumbup,
      thumbdown: thumbdown,
      feedback_choices: feedbackChoices,
      feedback_details: feedbackDetails,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    },
    withCredentials: true,
  };

  try {
    const response = await axios.post("/api/v1/thumbs", config);
    return response.data as ThumbsResponse[];
  } catch (error) {
    throw error;
  }
}
