import { useState } from "react";
import { useAppContext } from "../hooks/useAppContext";
import Privacy from "./SVGIcons/Privacy";

type PrivacyButtonProps = {
  className?: string;
  howToUseMouseOver: boolean;
  setHowToUseMouseOver: React.Dispatch<React.SetStateAction<boolean>>;
  privacyMouseOver: boolean;
  setPrivacyMouseOver: React.Dispatch<React.SetStateAction<boolean>>;
  onClick: () => void;
};

const PrivacyButton = (props: PrivacyButtonProps) => {
  const { getTranslation } = useAppContext();
  const [active, setActive] = useState(false);

  return (
    <>
      <button
        className={`relative flex h-[68px] items-center justify-start transition md:h-[150px] ${props.howToUseMouseOver || props.privacyMouseOver ? "opacity-0 duration-300" : "opacity-100 delay-[150ms]"} gap-2.5`}
        onClick={props.onClick}
        onMouseEnter={() => {
          if (window.innerWidth < 1280) {
            return;
          }

          props.setPrivacyMouseOver(true);
        }}
      >
        <Privacy className={``} />
      </button>
    </>
  );
};

export default PrivacyButton;
