import React from "react";
import { Tooltip } from "react-tooltip";
import { useAppContext } from "../../hooks/useAppContext";

export interface RedoButtonProps {
  className?: string;
  question: string;
  onSend: (question: string) => void;
  disabled: boolean;
}

const Redo = ({ className, question, onSend, disabled }: RedoButtonProps) => {
  const { getTranslation } = useAppContext();

  const sendQuestion = () => {
    if (disabled || !question.trim()) {
      return;
    }

    onSend(question);
  };

  return (
    <>
      <svg
        className={className}
        onClick={sendQuestion}
        data-tooltip-id="redo-tooltip"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <path d="M15.6433 8.5C16.3926 8.5 17 7.8732 17 7.1V2.43333C17 1.91787 16.5951 1.5 16.0956 1.5C15.596 1.5 15.1911 1.91787 15.1911 2.43333V5.34904C13.9568 3.05359 11.5842 1.5 8.85999 1.5C5.72181 1.5 3.05025 3.56164 2.04779 6.44403C1.84986 7.01314 2.29391 7.56667 2.87984 7.56667C3.30624 7.56667 3.67076 7.26484 3.83147 6.85727C4.63876 4.80997 6.58605 3.36667 8.85999 3.36667C11.0501 3.36667 12.9372 4.70551 13.7946 6.63333H11.1211C10.6216 6.63333 10.2167 7.0512 10.2167 7.56667C10.2167 8.08213 10.6216 8.5 11.1211 8.5H15.6433Z" />
        <path d="M2.35667 9.5C1.6074 9.5 1 10.1268 1 10.9V15.5667C1 16.0821 1.40493 16.5 1.90445 16.5C2.40396 16.5 2.80889 16.0821 2.80889 15.5667V12.651C4.04322 14.9464 6.4158 16.5 9.14001 16.5C12.2782 16.5 14.9497 14.4384 15.9522 11.556C16.1501 10.9869 15.7061 10.4333 15.1202 10.4333C14.6938 10.4333 14.3292 10.7352 14.1685 11.1427C13.3612 13.19 11.414 14.6333 9.14001 14.6333C6.94989 14.6333 5.06281 13.2945 4.20538 11.3667H6.8789C7.37841 11.3667 7.78335 10.9488 7.78335 10.4333C7.78335 9.91787 7.37841 9.5 6.8789 9.5H2.35667Z" />
      </svg>
      <Tooltip id="redo-tooltip" className="z-10">
        {getTranslation("Redo Tooltip")}
      </Tooltip>
    </>
  );
};

export default Redo;
