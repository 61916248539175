import React, { ChangeEvent, useLayoutEffect, useRef } from "react";
import Close from "./SVGIcons/Close";
import { useAppContext } from "../hooks/useAppContext";
import { Transition } from "@headlessui/react";

type ModalFeedbackProps = {
  _id: string;
  feedbackOpen: boolean;
  setFeedbackOpen: React.Dispatch<React.SetStateAction<boolean>>;
  onThumbDownClicked: (
    _id: string,
    feedbackChoices: string[],
    feedbackDetails: string,
  ) => Promise<void>;
};

const choices = [
  "Feedback #1",
  "Feedback #2",
  "Feedback #3",
  "Feedback #4",
  "Feedback #5",
];

const ModalFeedback = ({
  _id,
  feedbackOpen,
  setFeedbackOpen,
  onThumbDownClicked,
}: ModalFeedbackProps) => {
  const { getTranslation } = useAppContext();
  const [selectedFeedbackChoices, setSelectedFeedbackChoices] = React.useState<
    string[]
  >([]);
  const [error, setError] = React.useState("");
  const [feedbackDetails, setFeedbackDetails] = React.useState("");
  const textbox = useRef<HTMLTextAreaElement>(null);

  const adjustHeight = () => {
    if (!textbox.current) return;

    textbox.current.style.height = "inherit";
    textbox.current.style.height = `${textbox.current.scrollHeight}px`;
  };

  const sendFeedback = () => {
    if (selectedFeedbackChoices.length === 0 && !feedbackDetails) {
      setError("Please select at least one feedback or provide details.");
      return;
    }

    setFeedbackOpen(false);
    onThumbDownClicked(_id, selectedFeedbackChoices, feedbackDetails);
  };

  const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
    if (ev.key === "Enter" && !ev.shiftKey) {
      ev.preventDefault();
      sendFeedback();
    }
  };

  const onFeedbackChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.currentTarget.value;
    setFeedbackDetails(value);
    setError("");
    adjustHeight();
  };

  useLayoutEffect(adjustHeight, [feedbackDetails]);

  return (
    <>
      <Transition
        className="fixed -bottom-[370px] left-[calc(50%-300px)] z-10"
        show={feedbackOpen}
        as="div"
        enter="transition-translate duration-500"
        enterTo="-translate-y-[calc(50vh+50%)]"
        leave="transition-translate duration-500"
        leaveFrom="-translate-y-[calc(50vh+50%)]"
        leaveTo="translate-y-[calc(50vh+50%)]"
        appear={true}
      >
        <div className={`w-[600px] rounded-[32px] bg-white p-12 shadow`}>
          <Close
            className="width-[30px] height-[30px] absolute right-5 top-5 cursor-pointer hover:fill-primary"
            onClick={() => {
              setFeedbackOpen(false);
            }}
          />
          <div className="flex flex-col items-center justify-center gap-6 bg-white">
            <h1 className="text-center text-xl font-bold leading-7 text-zinc-800">
              {getTranslation("Provide additional feedback")}
            </h1>
            <div className="h-px w-full bg-gray-200" />
            <ul className="flex flex-wrap items-start justify-start gap-2">
              {choices.map((choice, index) => (
                <li
                  key={index}
                  className={`cursor-pointer rounded-[10px] p-2 text-xs leading-[17px] ${selectedFeedbackChoices.includes(getTranslation(choice) as string) ? "bg-[#7891FF] text-white hover:bg-primary-hover" : "bg-slate-100 font-normal text-primary hover:text-primary-hover"}`}
                  onClick={() => {
                    setError("");
                    setSelectedFeedbackChoices((prev) => {
                      let transChoice = getTranslation(choice) as string;
                      if (prev.includes(transChoice)) {
                        return prev.filter((item) => item !== transChoice);
                      } else {
                        return [...prev, transChoice];
                      }
                    });
                  }}
                >
                  {getTranslation(choice)}
                </li>
              ))}
            </ul>
            <div className="group flex max-h-[88px] w-full items-center gap-4 rounded-[18px] px-4 py-2 shadow ring-2 ring-indigo-300 hover:ring-[3px] focus:bg-[#7891FF]">
              <textarea
                className="max-h-[88px] w-full resize-none break-words text-indigo-500 focus:outline-none group-focus:bg-[#7891FF] group-focus:text-white group-focus:placeholder:text-white"
                placeholder={getTranslation("Feedback Placeholder")}
                value={feedbackDetails}
                onChange={onFeedbackChange}
                onKeyDown={onEnterPress}
                aria-label="Feedback"
                rows={3}
                ref={textbox}
              />
            </div>
            <div className="flex w-full items-center justify-start gap-4">
              <button
                className="flex h-[35px] w-[100px] items-center justify-center rounded-[14px] bg-indigo-400 px-6 py-3 text-base font-bold leading-snug text-white hover:bg-primary-hover"
                onClick={sendFeedback}
              >
                {getTranslation("Submit")}
              </button>
              <div className="flex h-[12px] items-center text-sm font-bold text-red-500">
                {error}
              </div>
            </div>
          </div>
        </div>
      </Transition>
    </>
  );
};

export default ModalFeedback;
