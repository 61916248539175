import { useAppContext } from "../../hooks/useAppContext";
import HowToUseButton from "../../components/HowToUseButton";
import PrivacyButton from "../../components/PrivacyButton";
import { useRef, useState, useEffect } from "react";
import { AxiosError } from "axios";
import { chatApi, thumbsApi } from "../../api/chatAPI";
import {
  RetrievalMode,
  ChatTurn,
  ChatRequest,
  Approaches,
} from "../../api/models";
import { Brand, Page } from "../../context/AppProvider";
import { InputQuestion } from "../../components/InputQuestion";
import Info from "../../components/SVGIcons/Info";
import { UserChatMessage } from "../../components/UserChatMessage";
import { Answer, Thumb } from "../../components/Answer";
import ModalOverlay from "../../components/ModalOverlay";
import ModalChatBot from "../../components/ModalChatBot";
import ModalHowToUse from "../../components/ModalHowToUse";
import ModalPrivacy from "../../components/ModalPrivacy";
import Redo from "../../components/SVGIcons/Redo";
import HowToUseSlider from "../../components/HowToUseSlider";
import PrivacySlider from "../../components/PrivacySlider";
import ChatProfile from "../../components/ChatProfile";
import { AnswerLoading } from "../../components/AnswerLoading";
import AnswerError from "../../components/AnswerError";
import SettingPanel from "../../components/SettingPanel";
import SettingButton from "../../components/SettingButton";
import { Field, Input, Label, Transition } from "@headlessui/react";
import SettingCheckBox from "../../components/SettingCheckBox";
import BrandButton from "../../components/BrandButton";
import ModalBrand from "../../components/ModalBrand";
import ModalBackground from "../../components/ModalBackground";

const Chat = () => {
  const {
    setPage,
    brand,
    setBrand,
    lastQuestionRef,
    error,
    setError,
    isLoading,
    setIsLoading,
    answers,
    setAnswers,
    setActiveCitation,
    locale,
    getTranslation,
  } = useAppContext();
  const [question, setQuestion] = useState<string>("");
  const [howToUseMouseOver, setHowToUseMouseOver] = useState<boolean>(false);
  const [privacyMouseOver, setPrivacyMouseOver] = useState<boolean>(false);
  const [infoOverlayOpen, setInfoOverlayOpen] = useState<boolean>(false);
  const [overlayContent, setOverlayContent] = useState<JSX.Element>(<></>);
  const [tokens, setTokens] = useState<number>(0);
  const [settingPanelOpen, setSettingPanelOpen] = useState(false);
  const [retrieveCount, setRetrieveCount] = useState<number>(6);
  const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
  const [useSemanticCaptions, setUseSemanticCaptions] =
    useState<boolean>(false);
  const [useChatRecord, setUseChatRecord] = useState<boolean>(true);
  const [useDummyAnswer, setUseDummyAnswer] = useState<boolean>(false);
  const [menuDropDown, setMenuDropDown] = useState(false);

  const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);

  const makeApiRequest = async (question: string) => {
    lastQuestionRef.current = question;

    error && setError(undefined);
    setIsLoading(true);
    setActiveCitation(undefined);
    setPage(Page.CHAT);

    try {
      const history: ChatTurn[] = answers.map((a) => ({
        user: a[0],
        bot: a[1].answer,
        brand: a[2],
      }));
      const addedHistory = [
        ...history,
        { user: question, bot: undefined, brand: brand },
      ];

      const request: ChatRequest = {
        history: getLastBrandHistory(addedHistory),
        approach: Approaches.ReadRetrieveRead,
        overrides: {
          top: retrieveCount,
          semanticRanker: useSemanticRanker,
          semanticCaptions: useSemanticCaptions,
          chatRecord: useChatRecord,
          dummyAnswer: useDummyAnswer,
          brand: brand,
        },
        locale: locale,
      };
      const result = await chatApi(request);
      setAnswers([...answers, [question, result, brand]]);
    } catch (e) {
      console.log(e);

      setError(e as AxiosError<{ error: string }>);
    } finally {
      setIsLoading(false);
    }
  };

  const getLastBrandHistory = (history: ChatTurn[]) => {
    if (history.length > 0) {
      const lastBrand = history[history.length - 1].brand;
      for (let i = history.length - 1; i >= 0; i--) {
        if (history[i].brand === lastBrand) {
          continue;
        }
        return history.slice(i + 1, history.length + 1);
      }
    }
    return history;
  };

  useEffect(() => {
    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" });
  }, [isLoading]);

  return (
    <>
      <div className="relative flex flex-col items-center justify-between w-full h-full gap-1 px-4 py-5 sm:px-5 sm:py-10 md:px-10 lg:px-16 lg:py-12">
        {!answers[0] ? (
          <>
            <div className="flex min-h-0 w-full flex-col items-center justify-center gap-14 overflow-y-auto overflow-x-hidden sm:grow medium:min-h-[465px] medium:gap-8 [&::-webkit-scrollbar-thumb]:bg-indigo-500 [&::-webkit-scrollbar-thumb]:[border-radius:10px] [&::-webkit-scrollbar]:[width:6px]">
              <div
                className={`relative min-w-0 md:h-[200px] md:w-[900px] ${howToUseMouseOver || privacyMouseOver ? "cursor-pointer" : ""}`}
                onMouseLeave={() => {
                  if (window.innerWidth < 1280) {
                    return;
                  }

                  setHowToUseMouseOver(false);
                  setPrivacyMouseOver(false);
                }}
              >
                <HowToUseSlider
                  howToUseMouseOver={howToUseMouseOver}
                  setHowToUseMouseOver={setHowToUseMouseOver}
                  privacyMouseOver={privacyMouseOver}
                  setPrivacyMouseOver={setPrivacyMouseOver}
                />
                <div
                  className={`flex h-full items-center justify-center gap-10 sm:gap-14 lg:gap-16`}
                >
                  <HowToUseButton
                    howToUseMouseOver={howToUseMouseOver}
                    setHowToUseMouseOver={setHowToUseMouseOver}
                    privacyMouseOver={privacyMouseOver}
                    setPrivacyMouseOver={setPrivacyMouseOver}
                    onClick={() => {
                      if (window.innerWidth < 1280) {
                        setInfoOverlayOpen(!infoOverlayOpen);
                        setOverlayContent(<ModalHowToUse />);
                      }
                    }}
                  />
                  <div
                    className={`absolute -left-10 h-full w-1/2 ${howToUseMouseOver ? "z-20" : "-z-10"}`}
                    onMouseLeave={() => {
                      if (window.innerWidth < 1280) {
                        return;
                      }

                      setHowToUseMouseOver(false);
                    }}
                  ></div>
                  <div
                    className={`h-[68px] w-[2px] border border-gray-200 transition ease-linear md:h-[150px] ${howToUseMouseOver ? "translate-x-[150px]" : ""} ${privacyMouseOver ? "-translate-x-[172.5px]" : ""} duration-[200ms]`}
                  />

                  <PrivacyButton
                    howToUseMouseOver={howToUseMouseOver}
                    setHowToUseMouseOver={setHowToUseMouseOver}
                    privacyMouseOver={privacyMouseOver}
                    setPrivacyMouseOver={setPrivacyMouseOver}
                    onClick={() => {
                      if (window.innerWidth < 1280) {
                        setInfoOverlayOpen(!infoOverlayOpen);
                        setOverlayContent(<ModalPrivacy />);
                      }
                    }}
                  />
                  <div
                    className={`absolute -right-10 h-full w-1/2 ${privacyMouseOver ? "z-20" : "-z-10"}`}
                    onMouseLeave={() => {
                      if (window.innerWidth < 1280) {
                        return;
                      }

                      setPrivacyMouseOver(false);
                    }}
                  ></div>
                </div>
                <PrivacySlider
                  howToUseMouseOver={howToUseMouseOver}
                  setHowToUseMouseOver={setHowToUseMouseOver}
                  privacyMouseOver={privacyMouseOver}
                  setPrivacyMouseOver={setPrivacyMouseOver}
                />
              </div>
              <h1 className="w-full text-center text-4xl font-extrabold leading-[50px] text-zinc-800 sm:text-[40px] md:text-5xl lg:text-[64px]">
                {getTranslation("How may I assist you today?")}
              </h1>
              <div className="flex-col items-center justify-center hidden gap-6 md:flex">
                <h6 className="text-base font-bold leading-snug text-center text-indigo-500">
                  {getTranslation("Chatbot Beta Service")}
                </h6>
                <p className="w-3/4 text-base leading-snug text-center text-indigo-500">
                  {getTranslation("Chatbot Beta Service Description")}
                </p>
                <ul className="flex items-center justify-center gap-4 py-4">
                  {brand === Brand.CLO3D && (
                    <>
                      <li className="text-center text-[11px] font-normal leading-[15px] text-neutral-500">
                        {getTranslation("Chatbot Sources #1")}
                      </li>
                      <li className="h-[3px] w-[3px] rounded-full bg-stone-300"></li>
                    </>
                  )}
                  <li className="text-center text-[11px] font-normal leading-[15px] text-neutral-500">
                    {getTranslation("Chatbot Sources #2")}
                  </li>
                  {brand === Brand.CLO3D && (
                    <>
                      <li className="h-[3px] w-[3px] rounded-full bg-stone-300"></li>
                      <li className="text-center text-[11px] font-normal leading-[15px] text-neutral-500">
                        {getTranslation("Chatbot Sources #3")}
                      </li>
                    </>
                  )}
                  <li className="h-[3px] w-[3px] rounded-full bg-stone-300"></li>
                  <li className="text-center text-[11px] font-normal leading-[15px] text-neutral-500">
                    {getTranslation("Chatbot Sources #4")}
                  </li>
                </ul>
              </div>
              <BrandButton brand={brand} setMenuDropDown={setMenuDropDown} />
              <Transition
                className="fixed -bottom-[224px] left-[calc(50vw-150px)] z-10 md:left-[calc(50vw-225px)]"
                show={menuDropDown}
                as="div"
                enter="transition-translate duration-[600ms]"
                enterTo="-translate-y-[calc(50vh+112px)]"
                leave="transition-translate duration-[600ms]"
                leaveFrom="-translate-y-[calc(50vh+112px)]"
                leaveTo="translate-y-[calc(50vh+112px)]"
                appear={true}
              >
                <ModalBrand
                  className={`w-[300px] md:w-[450px]`}
                  menuDropDown={menuDropDown}
                  setMenuDropDown={setMenuDropDown}
                />
              </Transition>
            </div>
            {menuDropDown && <ModalBackground setVisible={setMenuDropDown} />}
          </>
        ) : (
          <div className="w-full grow overflow-y-auto px-4 md:w-[1200px] [&::-webkit-scrollbar-thumb]:bg-indigo-500 [&::-webkit-scrollbar-thumb]:[border-radius:10px] [&::-webkit-scrollbar]:[width:6px]">
            {answers.map((answer, index) => (
              <div key={index}>
                {/* Question */}
                <UserChatMessage message={answer[0]} />
                {/* Answer */}
                <Answer
                  answers={answer[1]}
                  onFollowupQuestionClicked={(q) => makeApiRequest(q)}
                  RedoButton={
                    <Redo
                      className="h-[18px] w-[18px] cursor-pointer hover:fill-primary"
                      question={answer[0]}
                      disabled={isLoading}
                      onSend={(question) => makeApiRequest(question)}
                    />
                  }
                />
              </div>
            ))}
            {isLoading && (
              <>
                <UserChatMessage message={lastQuestionRef.current} />
                <div className="flex flex-col gap-3">
                  <ChatProfile />
                  <div className="flex h-[54px] w-[74px] items-center justify-center gap-4 rounded-br-[20px] rounded-tl-[20px] rounded-tr-[20px] border border-white bg-white bg-opacity-50 p-4 shadow">
                    <AnswerLoading className="bg-primary" />
                  </div>
                </div>
              </>
            )}
            {error ? (
              <>
                <UserChatMessage message={lastQuestionRef.current} />
                <div className="flex flex-col gap-3">
                  <ChatProfile />
                  <AnswerError
                    error={error}
                    onRetry={() =>
                      makeApiRequest(lastQuestionRef.current || "")
                    }
                  />
                </div>
              </>
            ) : null}
            <div ref={chatMessageStreamEnd} />
          </div>
        )}
        <InputQuestion
          question={question}
          setQuestion={setQuestion}
          tokens={tokens}
          setTokens={setTokens}
          settingPanelOpen={settingPanelOpen}
          setSettingPanelOpen={setSettingPanelOpen}
          onSend={(question) => makeApiRequest(question)}
          disabled={isLoading}
          firstQuestion={isLoading && !answers[0]}
          placeholder={getTranslation("Ask a question...")}
        />
        <button
          className="group flex cursor-pointer gap-1.5 md:hidden"
          onClick={() => {
            if (window.innerWidth < 1280) {
              setInfoOverlayOpen(!infoOverlayOpen);
              setOverlayContent(<ModalChatBot />);
            }
          }}
        >
          <p className="text-sm font-bold leading-tight text-center text-indigo-400 group-hover:text-indigo-500">
            {getTranslation("Chatbot Beta Service")}
          </p>
          <Info className="w-4 h-4 fill-indigo-400 group-hover:fill-indigo-500" />
        </button>
        <p className="hidden text-center text-[11px] font-normal leading-[15px] text-neutral-500 md:block lg:text-xs">
          {getTranslation("Chatbot Warning")}
        </p>
      </div>
      {settingPanelOpen && (
        <SettingPanel>
          <div className="flex flex-col items-center justify-center w-full gap-8">
            <h1 className="text-xl font-bold leading-tight text-center text-zinc-800">
              Settings
            </h1>
            <Field className="flex flex-col items-start w-full gap-2">
              <Label>Retrieved Documents</Label>
              <input
                className="w-full px-4 py-2 border-2 border-indigo-300 rounded-lg focus:outline-primary"
                type="number"
                value={retrieveCount}
                onChange={(e) => setRetrieveCount(Number(e.target.value))}
              />
            </Field>
            <SettingCheckBox
              label="Semantic Ranker"
              checked={useSemanticRanker}
              onChange={() => setUseSemanticRanker(!useSemanticRanker)}
            />
            <SettingCheckBox
              label="Semantic Captions"
              checked={useSemanticCaptions}
              onChange={() => setUseSemanticCaptions(!useSemanticCaptions)}
            />
            <SettingCheckBox
              label="Chat Record"
              checked={useChatRecord}
              onChange={() => {
                setUseChatRecord(!useChatRecord);
                setUseDummyAnswer(false);
              }}
            />
            <SettingCheckBox
              label="Dummy Answer"
              checked={useDummyAnswer}
              onChange={() => {
                setUseDummyAnswer(!useDummyAnswer);
                setUseChatRecord(false);
              }}
            />
          </div>
          <button
            className="w-full px-4 py-2 rounded-lg cursor-pointer ring-2 ring-inset ring-indigo-300 hover:border-0 hover:bg-primary hover:text-white"
            onClick={() => setSettingPanelOpen(false)}
          >
            Close
          </button>
        </SettingPanel>
      )}
      <ModalOverlay open={infoOverlayOpen} setOpen={setInfoOverlayOpen}>
        {overlayContent}
      </ModalOverlay>
    </>
  );
};

export default Chat;
