import hamburger from "../assets/hamburger.svg";

type HamburgerButtonProps = {
  setMenuDropDown: React.Dispatch<React.SetStateAction<boolean>>;
};

const HamburgerButton = ({ setMenuDropDown }: HamburgerButtonProps) => {
  return (
    <button
      className="h-[18px] w-[18px]"
      onClick={() => setMenuDropDown((menuDropDown) => !menuDropDown)}
    >
      <img className="" src={hamburger} />
    </button>
  );
};

export default HamburgerButton;
