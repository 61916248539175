const DropDownArrow = ({ className }: { className: string }) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0453 6.30416C17.7388 5.44947 17.6264 4.17921 16.7942 3.46696C16.0139 2.79923 14.8781 2.85899 14.1677 3.57332L14.0315 3.72489L9.00131 9.92582L3.96846 3.72489C3.31828 2.92361 2.19055 2.77204 1.36639 3.3431L1.20584 3.46696C0.425621 4.13469 0.278031 5.29286 0.834077 6.13927L0.954689 6.30416L7.37391 14.2152C8.22303 15.2616 9.77696 15.2616 10.6261 14.2152L17.0453 6.30416Z"
      />
    </svg>
  );
};

export default DropDownArrow;
