import { AxiosError } from "axios";
import ErrorCircle from "./SVGIcons/ErrorCircle";

interface AnswerErrorProps {
  error: AxiosError<{ error: string }>;
  onRetry: () => void;
}

const AnswerError = ({ error, onRetry }: AnswerErrorProps) => {
  return (
    <div className="bg-white/opacity-50 flex flex-col items-start justify-start gap-4 rounded-br-[20px] rounded-tl-[20px] rounded-tr-[20px] border border-white p-4 shadow">
      <ErrorCircle className={"h-6 w-6 fill-red-600"} />
      <p>{error.response?.data.error}</p>
      <button
        className="flex items-center justify-start rounded-3xl bg-indigo-400 p-2.5 text-center text-base font-bold leading-snug text-white hover:bg-indigo-500 hover:shadow-lg"
        onClick={onRetry}
      >
        Retry
      </button>
    </div>
  );
};

export default AnswerError;
