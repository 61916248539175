import { renderToStaticMarkup } from "react-dom/server";
import { Locale } from "../context/AppProvider";

export function parseAnswerToHtml(
  answer: string,
  followupQuestions: string[],
  locale: Locale,
) {
  const citations: string[] = [];

  // trim any whitespace from the end of the answer after removing follow-up questions
  let parsedAnswer = answer.trim();

  const parts = parsedAnswer.split(/\[([^\]]+)\]/g);

  const fragments = parts.map((part, index) => {
    if (index % 2 === 0) {
      // Replace subject of each step-by-step with <strong> tags
      part = part.replaceAll(/(\d{1,2}\.\s?)(.*?):/gm, "$1<strong>$2</strong>");

      // Replace all links with <a> tags
      part = part.replaceAll(
        /(https:\/\/[^\s]+)/g,
        '<a href="$1" target="_blank">$1</a>',
      );

      // Replace all 'Contact Us' with <a> tags
      part = part.replaceAll(
        "Contact Us",
        `<a href="https://clo3d.com/${locale}/support/contactus" target="_blank">Contact Us</a>`,
      );

      return part;
    } else {
      let citationIndex: number;
      if (citations.indexOf(part) !== -1) {
        citationIndex = citations.indexOf(part) + 1;
      } else {
        citations.push(part);
        citationIndex = citations.length;
      }

      return renderToStaticMarkup(
        <a
          className="inline-block cursor-pointer rounded-lg bg-slate-100 p-1 text-xs font-normal leading-[14px] text-indigo-500 hover:text-primary-hover"
          href={part}
        >
          {citationIndex}
        </a>,
      );
    }
  });

  return {
    answerHtml: fragments.join(""),
    citations,
    followupQuestions,
  };
}
