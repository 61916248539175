import React from "react";
import { Tooltip } from "react-tooltip";
import { useAppContext } from "../../hooks/useAppContext";

type ThumbsDownProps = {
  className?: string;
  onClick?: () => void;
};

const ThumbsDown = ({ className, onClick }: ThumbsDownProps) => {
  const { getTranslation } = useAppContext();

  return (
    <>
      <svg
        className={className}
        onClick={onClick}
        data-tooltip-id="thumbs-down-tooltip"
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 18 18"
      >
        <g clipPath="url(#clip0_94_4069)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M5.96106 16.8377C5.88054 16.6124 5.86951 16.3686 5.92936 16.1371L6.87273 12.4839H1.63636C1.20237 12.4839 0.786158 12.314 0.479279 12.0115C0.172402 11.709 8.75952e-07 11.2988 8.75952e-07 10.871V9.17422C-0.000220376 8.96345 0.0414743 8.75467 0.122727 8.5597L2.655 2.49921C2.71677 2.35145 2.82169 2.22511 2.95644 2.13624C3.09118 2.04736 3.24968 1.99994 3.41182 2.00001H12.2727C12.4897 2.00001 12.6978 2.08498 12.8513 2.23622C13.0047 2.38745 13.0909 2.59258 13.0909 2.80647V12.15C13.0909 12.3639 13.0046 12.569 12.8512 12.7202L7.61482 17.8815C7.54522 17.9503 7.45277 17.992 7.35446 17.999C7.25614 18.0059 7.15859 17.9777 7.07973 17.9194L6.38182 17.4033C6.18799 17.2598 6.04158 17.0631 5.96106 16.8377ZM1.7 10.7839V9.1943L3.9957 3.70001H11.3909V11.7725L7.93174 15.1821L9.06748 10.7839H1.7Z"
          />
          <path d="M14.7274 11.6774H17.1819C17.3989 11.6774 17.607 11.5925 17.7604 11.4412C17.9139 11.29 18.0001 11.0849 18.0001 10.871V2.80645C18.0001 2.59257 17.9139 2.38744 17.7604 2.2362C17.607 2.08496 17.3989 2 17.1819 2H14.7274V11.6774Z" />
        </g>
        <defs>
          <clipPath id="clip0_94_4069">
            <rect width="18" height="18" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Tooltip id="thumbs-down-tooltip" className="z-10">
        {getTranslation("Thumbs Down Tooltip")}
      </Tooltip>
    </>
  );
};

export default ThumbsDown;
