import HowToUseIcon from "../../assets/how_to_use.svg";
import { useAppContext } from "../../hooks/useAppContext";

type HowToUseProps = {
  className?: string;
};

const HowToUse = ({ className }: HowToUseProps) => {
  const { getTranslation } = useAppContext();

  return (
    <div
      className={`${className} flex min-w-[90px] cursor-pointer flex-col items-center justify-center gap-2.5`}
    >
      <img
        className="h-[50px] w-[50px] md:h-[80px] md:w-[80px]"
        src={HowToUseIcon}
      />
      <span className="text-center text-xs font-bold leading-[17px] text-zinc-800">
        {getTranslation("How to Use")}
      </span>
    </div>
  );
};

export default HowToUse;
