import React from "react";
import Privacy from "../assets/privacy.svg";
import { useAppContext } from "../hooks/useAppContext";
import HowToUse from "./SVGIcons/HowToUse";

type PrivacySliderProps = {
  className?: string;
  howToUseMouseOver: boolean;
  setHowToUseMouseOver: React.Dispatch<React.SetStateAction<boolean>>;
  privacyMouseOver: boolean;
  setPrivacyMouseOver: React.Dispatch<React.SetStateAction<boolean>>;
};

const PrivacySlider = (props: PrivacySliderProps) => {
  const { getTranslation } = useAppContext();

  return (
    <div
      className={`absolute right-[calc(50%-325px)] top-0 flex h-full w-full cursor-pointer gap-16 ${props.privacyMouseOver ? "z-10 opacity-100 transition delay-[190ms]" : "-z-10 opacity-0"} gap-[128px]`}
    >
      <HowToUse
        className={`absolute left-[420px] h-full ${props.privacyMouseOver ? "z-20 animate-leanLeftBack [animation-delay:30ms]" : ""}`}
        // onMouseEnter={() => {
        //   if (window.innerWidth < 1280) {
        //     return;
        //   }
        //   props.setPrivacyMouseOver(false);
        // }}
      />
      <div
        className={`flex h-full items-center justify-end gap-12 ${props.privacyMouseOver ? "grow" : ""}`}
      >
        <div
          className={`flex h-full min-w-[90px] flex-col items-center justify-center gap-2.5`}
        >
          <img
            className="h-[50px] w-[50px] md:h-[80px] md:w-[80px]"
            src={Privacy}
          />
          <span className="text-center text-xs font-bold leading-[17px] text-zinc-800">
            {getTranslation("Privacy Notice")}
          </span>
        </div>
        <ul className="w-[287px]">
          <li
            className={`list-disc text-left text-xs font-normal leading-[17px] text-zinc-800`}
          >
            {getTranslation("Privacy Policy #1")}
          </li>
          <li
            className={`list-disc text-left text-xs font-normal leading-[17px] text-zinc-800`}
          >
            {getTranslation("Privacy Policy #2")}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PrivacySlider;
