import { Brand, Locale } from "../context/AppProvider";

export const enum Approaches {
  RetrieveThenRead = "rtr",
  ReadRetrieveRead = "rrr",
  ReadDecomposeAsk = "rda",
}

export const enum RetrievalMode {
  Hybrid = "hybrid",
  Vectors = "vectors",
  Text = "text",
}

export type AskRequestOverrides = {
  semanticRanker?: boolean;
  semanticCaptions?: boolean;
  top?: number;
  temperature?: number;
  chatRecord?: boolean;
  dummyAnswer?: boolean;
  brand: Brand;
};

export type AskRequest = {
  question: string;
  approach: Approaches;
  overrides?: AskRequestOverrides;
};

export type Articles = {
  title: string;
  url: string;
};

export type AskResponse = {
  _id: string;
  answer: string;
  follow_up_questions: string[];
  thoughts: string | null;
  data_points: string[];
  error?: string;
  citation_articles?: Articles[];
  suggested_articles?: Articles[];
  suggested_videos?: Articles[];
  youtube_links: string[];
  overrides?: AskRequestOverrides;
};

export type ThumbsResponse = {
  thumbsUp: number;
  thumbsDown: number;
};

export type ChatTurn = {
  brand?: Brand;
  user: string;
  bot?: string;
};

export type ChatRequest = {
  history: ChatTurn[];
  approach: Approaches;
  overrides?: AskRequestOverrides;
  locale: Locale;
};
