import React from "react";

const ModalBackground = ({
  setVisible,
}: {
  setVisible: (value: React.SetStateAction<boolean>) => void;
}) => {
  return (
    <div
      className="fixed left-0 top-0 z-[5] h-screen w-screen bg-white/50"
      onClick={(visible) => setVisible(false)}
    ></div>
  );
};

export default ModalBackground;
