import { useEffect, useState } from "react";
import { Outlet, useSearchParams } from "react-router-dom";
import { Brand, Locale, Page } from "../../context/AppProvider";
import { useAppContext } from "../../hooks/useAppContext";
import { ClearChatButton } from "../../components/ClearChatButton";
import axios from "axios";
import HamburgerButton from "../../components/HamburgerButton";
import ModalBrand from "../../components/ModalBrand";
import SignInOutButton from "../../components/SignInOutButton";
import { Transition } from "@headlessui/react";
import ModalBackground from "../../components/ModalBackground";
import bgVideo from "../../assets/bg.mp4";
import Toast from "../../components/Toast";
import ThumbsUp from "../../components/SVGIcons/ThumbsUp";
import ThumbsDown from "../../components/SVGIcons/ThumbsDown";
import BrandButtonNav from "../../components/BrandButtonNav";

export type User = {
  user_id: number;
  name: string;
  email: string;
};

const Layout = () => {
  const [searchParams] = useSearchParams();
  const {
    page,
    setPage,
    brand,
    setBrand,
    lastQuestionRef,
    error,
    setError,
    setActiveCitation,
    isLoading,
    answers,
    setAnswers,
    setLocale,
    getTranslation,
  } = useAppContext();
  const [user, setUser] = useState<User>();
  const [showToast, setShowToast] = useState(false);
  const [menuDropDown, setMenuDropDown] = useState(false);

  const clearChat = () => {
    if (!(!lastQuestionRef.current || isLoading)) {
      lastQuestionRef.current = "";
      error && setError(undefined);
      setActiveCitation(undefined);
      setAnswers([]);
      setPage(Page.HOME);
    }
  };

  useEffect(() => {
    setBrand(
      searchParams.get("brand") === "closet" ? Brand.CLOSET : Brand.CLO3D,
    );
    setLocale(
      searchParams.get("locale") !== null
        ? (searchParams.get("locale") as Locale)
        : Locale.English,
    );

    // const signIn = async () => {
    //   try {
    //     const axiosResponse = await axios.post("/api/v1/signin", {
    //       email: "",
    //       password: "",
    //     });

    //     console.log(axiosResponse);
    //     setCookies("jwt_token", axiosResponse.data.token);
    //   } catch (error) {
    //     console.log(error);
    //   }
    // };
    // if (import.meta.env.VITE_EMAIL && import.meta.env.VITE_PASSWORD) signIn();

    // Get user info
    if (!user) {
      (async () => {
        try {
          const axiosResponse = await axios.get("/api/v1/user", {
            withCredentials: true,
          });

          setUser(axiosResponse.data);
        } catch (error) {
          console.log(error);
          setUser(undefined);
        }
      })();
    }
  }, []);

  useEffect(() => {
    if (answers.length === 1) {
      setShowToast(true);
    }
  }, [answers]);

  return (
    <>
      <nav className="flex h-[72px] w-full justify-between bg-white px-4 py-[10px] shadow sm:px-5 md:px-10 md:py-3 lg:px-16">
        <div className="flex items-center gap-x-[6px] md:hidden">
          <div className="flex h-[30px] w-[30px] items-center justify-center rounded-xl p-1.5">
            <HamburgerButton setMenuDropDown={setMenuDropDown} />
          </div>
          <div className="text-base font-bold leading-snug text-zinc-800">
            {brand}
          </div>
        </div>
        <div className="hidden md:block">
          {answers[0] && <BrandButtonNav setMenuDropDown={setMenuDropDown} />}
        </div>
        <div className="flex items-center gap-x-4">
          {user ? (
            <>
              <p className="text-sm font-bold leading-tight text-center text-neutral-400">
                Hello,{" "}
                <span className="text-sm font-bold leading-tight text-center text-zinc-800">
                  {user.name}
                </span>
              </p>
              <SignInOutButton type="Sign Out" />
            </>
          ) : (
            <SignInOutButton type="Sign In" />
          )}
          <ClearChatButton
            onClick={clearChat}
            disabled={!lastQuestionRef.current || isLoading}
          />
        </div>
      </nav>

      {/* Desktop */}
      <Transition
        className="absolute -left-[450px] top-[65px] z-10 hidden md:block"
        show={menuDropDown}
        as="div"
        appear={true}
        enter="transition-translate duration-[600ms]"
        enterTo="translate-x-[450px]"
        leave="transition-translate duration-[600ms]"
        leaveFrom="translate-x-[450px]"
        leaveTo="-translate-x-[450px]"
      >
        <ModalBrand
          className={`w-[450px]`}
          menuDropDown={menuDropDown}
          setMenuDropDown={setMenuDropDown}
        />
      </Transition>

      {/* Mobile */}
      <Transition
        className="absolute -bottom-[36px] left-[calc(50vw-150px)] z-10 w-[300px] sm:left-[calc(50vw-175px)] sm:w-[350px] md:hidden"
        show={menuDropDown}
        as="div"
        enter="transition-translate duration-[600ms]"
        enterTo="-translate-y-[calc(50vh-50%+72px)]"
        leave="transition-translate duration-[600ms]"
        leaveFrom="-translate-y-[calc(50vh-50%+72px)]"
        leaveTo="translate-y-[calc(50vh-50%+72px)]"
        appear={true}
      >
        <ModalBrand
          className={`w-[300px] sm:w-[350px]`}
          menuDropDown={menuDropDown}
          setMenuDropDown={setMenuDropDown}
        />
      </Transition>

      <main className="relative h-[calc(100%-72px)] overflow-y-auto overflow-x-hidden">
        <Outlet />
        {menuDropDown && <ModalBackground setVisible={setMenuDropDown} />}
      </main>

      {/* Background video */}
      <video
        className="absolute top-0 left-0 w-full h-full -z-10"
        src={bgVideo}
        playsInline
        autoPlay
        muted
        loop
      ></video>

      {/* Toast Notification */}
      <Toast
        showToast={showToast}
        setShowToast={setShowToast}
        iconElement={
          <div className="flex gap-2">
            <ThumbsUp className="w-4 h-4 fill-white" />
            <ThumbsDown className="w-4 h-4 fill-white" />
          </div>
        }
        message={getTranslation("Feedback Request Message")}
      />
    </>
  );
};

export default Layout;
