import { Checkbox, Field, Label } from "@headlessui/react";

type SettingCheckBoxProps = {
  label: string;
  checked: boolean;
  onChange: () => void;
};

const SettingCheckBox = ({
  label,
  checked,
  onChange,
}: SettingCheckBoxProps) => {
  return (
    <Field className="flex w-full items-center gap-3">
      <Checkbox
        checked={checked}
        onChange={onChange}
        className="group block size-6 rounded border bg-white data-[checked]:bg-primary"
      >
        <svg
          className="stroke-white opacity-0 group-data-[checked]:opacity-100"
          viewBox="0 0 14 14"
          fill="none"
        >
          <path
            d="M3 8L6 11L11 3.5"
            strokeWidth={2}
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </Checkbox>
      <Label>{label}</Label>
    </Field>
  );
};

export default SettingCheckBox;
