import React, { useState } from "react";
import { Brand } from "../context/AppProvider";
import { useAppContext } from "../hooks/useAppContext";
import DropDownArrow from "./SVGIcons/DropDownArrow";
import CLO from "../assets/clo.svg";
import CLOSET from "../assets/closet.svg";
import MD from "../assets/md.svg";

export type BrandProps = {
  brand: Brand;
  setMenuDropDown: React.Dispatch<React.SetStateAction<boolean>>;
};

const BrandButton = ({ brand, setMenuDropDown }: BrandProps) => {
  const { getTranslation } = useAppContext();
  const [hover, setHover] = useState(false);

  return (
    <div className="flex flex-col gap-3.5">
      <p className="text-center text-[10px] font-normal leading-[15px] text-zinc-800 md:hidden">
        {getTranslation("Interested in other products?")}
      </p>
      <div className={`relative ${hover && "group"} flex justify-center`}>
        <button
          className="absolute z-[5] h-full w-[300px] cursor-pointer"
          onClick={() => setMenuDropDown((menuDropDown) => !menuDropDown)}
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
        />
        <div className="relative flex items-center gap-3">
          <div
            className={`flex items-center gap-3 transition-all duration-300 ease-linear md:group-hover:-translate-x-[110px]`}
          >
            {brand === Brand.CLO3D && (
              <img className="h-10 w-10" src={CLO} alt="CLO" />
            )}
            {brand === Brand.CLOSET && (
              <img className="h-10 w-10" src={CLOSET} alt="CLOSET" />
            )}
            {brand === Brand.MD && import.meta.env.VITE_ENV === "DEV" && (
              <img className="h-10 w-10" src={MD} alt="CLO" />
            )}
            <span className="text-base font-bold leading-[34px] text-zinc-800 md:text-xl md:group-hover:text-primary">
              {brand === Brand.MD
                ? "Marvelous Designer"
                : brand === Brand.CLOSET
                  ? "CLO-SET"
                  : "CLO3D"}
            </span>
          </div>
          <p className="absolute -right-[157px] text-sm font-normal leading-[15px] text-zinc-800 opacity-0 transition-all duration-300 ease-linear md:group-hover:-translate-x-[110px] md:group-hover:opacity-100">
            {getTranslation("Interested in other products?")}
          </p>
          <DropDownArrow
            className={
              "mb-[2px] fill-indigo-300 transition-all duration-300 ease-linear md:group-hover:translate-x-[75px] md:group-hover:fill-primary"
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BrandButton;
