import { AxiosError } from "axios";
import React, { useRef, useState } from "react";
import { AskResponse } from "../api/models";
import en from "../locales/en.json";
import ko from "../locales/ko.json";
import zh from "../locales/zh.json";
import ja from "../locales/ja.json";
import es from "../locales/es.json";
import pt from "../locales/pt.json";
import fr from "../locales/fr.json";
import translation from "../locales/translation.json";

export enum Brand {
  CLO3D = "CLO3D",
  CLOSET = "CLOSET",
  MD = "MD",
}

export enum Page {
  HOME = "HOME",
  CHAT = "CHAT",
  DASHBOARD = "DASHBOARD",
}

export enum Locale {
  // Use ISO 639 language codes
  English = "en",
  Korean = "ko",
  Chinese = "zh",
  Japanese = "ja",
  Spanish = "es",
  Portuguese = "pt",
  French = "fr",
}

type AppContextType = {
  page: Page;
  setPage: React.Dispatch<React.SetStateAction<Page>>;
  brand: Brand;
  setBrand: React.Dispatch<React.SetStateAction<Brand>>;
  lastQuestionRef: React.MutableRefObject<string>;
  error: AxiosError<{ error: string }, any> | undefined;
  setError: React.Dispatch<
    React.SetStateAction<AxiosError<{ error: string }, any> | undefined>
  >;
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  answers: [user: string, response: AskResponse, brand: Brand][];
  setAnswers: React.Dispatch<
    React.SetStateAction<[user: string, response: AskResponse, brand: Brand][]>
  >;
  activeCitation: string | undefined;
  setActiveCitation: React.Dispatch<React.SetStateAction<string | undefined>>;
  locale: Locale;
  setLocale: React.Dispatch<React.SetStateAction<Locale>>;
  getTranslation: (key: string) => string | undefined;
};

type AppProviderType = {
  children: React.ReactNode;
};

type TranslationType = { [key: string]: { [key: string]: string | undefined } };

export const AppContext = React.createContext<AppContextType>(
  {} as AppContextType,
);

const AppProvider = ({ children }: AppProviderType) => {
  const [page, setPage] = useState(Page.HOME);
  const [brand, setBrand] = useState(Brand.CLO3D);
  const lastQuestionRef = useRef<string>("");
  const [error, setError] = useState<AxiosError<{ error: string }>>();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [answers, setAnswers] = useState<
    [user: string, response: AskResponse, brand: Brand][]
  >([]);
  const [activeCitation, setActiveCitation] = useState<string>();
  const [locale, setLocale] = useState<Locale>(Locale.English);

  const getTranslation = (key: string) => {
    const t: TranslationType = translation;

    if (t[key] === undefined) {
      console.log(key);

      return "N/A";
    }

    return t[key][locale];
  };

  const value = {
    page,
    setPage,
    brand,
    setBrand,
    lastQuestionRef,
    error,
    setError,
    isLoading,
    setIsLoading,
    answers,
    setAnswers,
    activeCitation,
    setActiveCitation,
    locale,
    setLocale,
    getTranslation,
  };

  return <AppContext.Provider value={value}>{children}</AppContext.Provider>;
};

export default AppProvider;
