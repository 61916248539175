import React from "react";
import { useAppContext } from "../hooks/useAppContext";

const ModalChatBot = () => {
  const { getTranslation } = useAppContext();

  return (
    <>
      <h1 className="text-center text-sm font-bold leading-tight text-zinc-800 sm:text-base">
        {getTranslation("Chatbot Beta Service")}
      </h1>
      <p className="text-center text-xs font-normal leading-[17px] text-indigo-500 sm:text-sm">
        {getTranslation("Chatbot Beta Service Description")}
      </p>
      <ul className="flex flex-col items-center justify-center gap-2.5">
        <li className="h-[3px] w-[3px] rounded-full bg-stone-300"></li>
        <li className="text-center text-[10px] font-normal leading-[14px] text-neutral-400 sm:text-[12px]">
          {getTranslation("Chatbot Sources #4")}
        </li>
        <li className="text-center text-[10px] font-normal leading-[14px] text-neutral-400 sm:text-[12px]">
          {getTranslation("Chatbot Sources #3")}
        </li>
        <li className="text-center text-[10px] font-normal leading-[14px] text-neutral-400 sm:text-[12px]">
          {getTranslation("Chatbot Sources #1")}
        </li>
        <li className="text-center text-[10px] font-normal leading-[14px] text-neutral-400 sm:text-[12px]">
          {getTranslation("Chatbot Sources #2")}
        </li>
        <li className="h-[3px] w-[3px] rounded-full bg-stone-300"></li>
      </ul>
      <p className="text-center text-[10px] font-normal leading-[14px] text-neutral-500 sm:text-[12px]">
        {getTranslation("Chatbot Warning")}
      </p>
    </>
  );
};

export default ModalChatBot;
