import { useAppContext } from "../hooks/useAppContext";
import Ani from "../assets/ani.webm";

interface Props {
  onClick: () => void;
  disabled?: boolean;
}

export const ClearChatButton = ({ disabled, onClick }: Props) => {
  const { getTranslation } = useAppContext();

  return (
    <button
      className="relative flex h-[52px] cursor-pointer items-center rounded-[20px] bg-indigo-400 p-1.5 hover:bg-indigo-500 hover:shadow-lg"
      disabled={disabled}
      onClick={onClick}
    >
      <video className="w-10 h-10" src={Ani} autoPlay loop muted playsInline />
      {/* <Ani className="w-10 h-10" /> */}
      <span className="hidden p-2 text-base font-bold leading-snug text-center text-white md:block">
        {getTranslation("New chat")}
      </span>
    </button>
  );
};
