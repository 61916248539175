import React from "react";
import { User } from "../pages/layout/Layout";
import { useAppContext } from "../hooks/useAppContext";

type SignInButtonProps = {
  type?: string;
  className?: string;
};

const SignInOutButton = ({ type, className }: SignInButtonProps) => {
  const { brand, getTranslation } = useAppContext();
  return (
    <button
      className={`${className} rounded-[20px] p-[10px] text-center text-sm font-bold leading-tight text-indigo-400 hover:text-primary-hover active:bg-slate-50 md:text-base`}
      onClick={() =>
        (window.location.href =
          type === "Sign In"
            ? import.meta.env.VITE_ENV === "DEV"
              ? `https://style.clo-set.com/account/signin?returnUrl=https://aichat-dev.clo-set.com/?brand=${brand}`
              : `https://style.clo-set.com/account/signin?returnUrl=https://aichat.clo-set.com/?brand=${brand}`
            : import.meta.env.VITE_ENV === "DEV"
              ? `https://style.clo-set.com/account/logout?returnUrl=https://aichat-dev.clo-set.com/?brand=${brand}`
              : `https://style.clo-set.com/account/logout?returnUrl=https://aichat.clo-set.com/?brand=${brand}`)
      }
    >
      {type === "Sign In"
        ? getTranslation("Sign In")
        : getTranslation("Sign Out")}
    </button>
  );
};

export default SignInOutButton;
