export const AnswerLoading = ({ className }: { className?: string }) => {
  return (
    <div className="flex items-center justify-start gap-1">
      <div className={`${className} h-2.5 w-2.5 animate-flash rounded-full`} />
      <div
        className={`${className} h-2.5 w-2.5 animate-flash rounded-full [animation-delay:200ms]`}
      />
      <div
        className={`${className} h-2.5 w-2.5 animate-flash rounded-full [animation-delay:400ms]`}
      />
    </div>
  );
};
