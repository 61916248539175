import React from "react";

const Close = ({
  className,
  onClick,
}: {
  className: string;
  onClick: () => void;
}) => {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      onClick={onClick}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.724 15.724C15.356 16.092 14.7594 16.092 14.3914 15.724L9 10.3328L3.6086 15.724C3.27128 16.0613 2.74183 16.0894 2.37249 15.8083L2.27599 15.724C1.93867 15.3867 1.91056 14.8572 2.19166 14.4879L2.27599 14.3914L7.66717 9L2.27599 3.6086C1.908 3.24061 1.908 2.64398 2.27599 2.27599C2.64398 1.908 3.24061 1.908 3.6086 2.27599L9 7.66717L14.3914 2.27599C14.7287 1.93867 15.2582 1.91056 15.6275 2.19166L15.724 2.27599C16.0613 2.61332 16.0894 3.14276 15.8083 3.51211L15.724 3.6086L10.3328 9L15.724 14.3914C16.092 14.7594 16.092 15.356 15.724 15.724Z"
      />
    </svg>
  );
};

export default Close;
